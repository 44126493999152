import moment from 'moment';
import React, { useLayoutEffect, useMemo, useCallback, useContext, useRef, useState } from 'react';
import {
  ActivityIndicator,
  Platform,
  ScrollView,
  StyleSheet,
  Switch,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import CheckBox from 'expo-checkbox';
import {
  BottomSheetModal,
  BottomSheetBackdrop,
  BottomSheetFooter
} from '@gorhom/bottom-sheet';
import { BottomSheetScrollView } from '../../common/libs/bottom-sheet';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import useKeyboard from '@rnhooks/keyboard';
import Toast from 'react-native-toast-message';
import uuid from 'react-native-uuid';

import * as API from '../../common/api.service';
import { Theme } from '../../../theme';
import { translate, getCurrentLocale } from '../../../lang';
import { Context } from '../../Context';
import { LocalizedInput } from '../../common/localized-input.component';
import { HeaderComponent } from '../../common/header.component';
import { SelectorComponent } from '../../common/selector.component';
import { ButtonComponent } from '../../common/button.component';
import { InlineInputComponent } from '../../common/inline-input.component';
import { getPriceString, getRangePrice } from '../../common/utils.service';
import { Picker } from '../../common/libs/picker/picker';
import { DatePickerComponent } from '../../common/datepicker.component';
import { TimePicker, toUTC } from '../../common/libs/time-picker/time-picker';
import { isResourceAvailable, ResourceTypes } from '../resources/detail';
import { ResourcesListComponent } from '../resources/list';

const styles = StyleSheet.create({
  inputLabelWeb: {
    display: 'block',
    paddingRight: 16
  },
  media: {
    minHeight: 200,
    height: 200,
    paddingHorizontal: 8
  },
  weekday: {
    borderRadius: 20,
    width: 35,
    height: 35,
    borderWidth: 1,
    borderColor: Theme.palette.backgroundDark
  },
  weekdayActive: {
    borderWidth: 0,
    backgroundColor: Theme.palette.accent
  },
  actionColumn: {
    width: 30,
    ...Theme.styles.alignEndCenter
  },
});

const DATE_RANGE_BASIS_DICT = {
  day: 'DAY/NIGHT',
  period: 'PERIOD'
};

const DEFAULT_PLAN = {
  dateRange: false,
  dateRangeBasis: 'day',
  dateRangeUnit: 'night'
};

const DEFAULT_PRICING_RANGE = {
  basis: 'age',
  groupPriceBasis: 'group',
  baseAdults: 0,
  baseKids: 0,
  baseInfants: 0,
  extraAdult: 0,
  extraKid: 0,
  extraInfant: 0,
  start: moment().startOf('year').format('YYYY-MM-DD'),
  end: moment().add(1, 'year').endOf('year').format('YYYY-MM-DD')
};

const DEFAULT_SCHEDULE = {
  weekdays: [0, 1, 2, 3, 4, 5, 6],
  startTime: toUTC(new Date())
};

const sortTime = (s1, s2) => {
  moment(s1.startTime).format('HH:mm') > moment(s2.startTime).format('HH:mm') ? 1 : -1;
};

export const ContentPlanComponent = ({ navigation, route }) => {
  let { groups, group, service, plan, key } = (route.params || {});
  plan = plan || { ...DEFAULT_PLAN };
  const langs = process.env.LANGS.split(',');
  const context = useContext(Context);
  const locale = getCurrentLocale();
  const assignResourcesBottomSheetModalRef = useRef(null);
  const pricingBottomSheetModalRef = useRef(null);
  const scheduleBottomSheetModalRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(plan?.active !== false);
  const [type, setType] = useState(plan?.type || service.type || 'default');
  const [title, setTitle] = useState(plan?.title || {});
  const [subtitle, setSubtitle] = useState(plan?.subtitle || {});
  const [description, setDescription] = useState(plan?.description || {});
  const [policy, setPolicy] = useState(plan?.policy || {});
  const [resources, setResources] = useState(plan?.resources || []);
  const [pricingRanges, setPricingRanges] = useState(plan?.pricingRanges || []);
  const [selectedPricingRange, setSelectedPricingRange] = useState(DEFAULT_PRICING_RANGE);
  const [duration, setDuration] = useState(plan?.duration || 0);
  const [dateRange, setDateRange] = useState(plan?.dateRange || false);
  const [minDateRange, setMinDateRange] = useState(plan?.minDateRange || 1);
  const [maxDateRange, setMaxDateRange] = useState(plan?.maxDateRange || null);
  const [dateRangeBasis, setDateRangeBasis] = useState(plan?.dateRangeBasis || 'day');
  const [dateRangeUnit, setDateRangeUnit] = useState(plan?.dateRangeUnit || 'night');
  const [schedules, setSchedules] = useState(plan?.schedules || []);
  const [selectedSchedule, setSelectedSchedule] = useState({...DEFAULT_SCHEDULE});

  const [visible] = useKeyboard();
  const snapPoints = useMemo(() => ['80%'], []);

  const toggleWeekDay = (day) => {
    const idx = selectedSchedule.weekdays.indexOf(day);
    if (idx === -1) {
      selectedSchedule.weekdays.push(day);
    } else {
      selectedSchedule.weekdays.splice(idx, 1);
    }
    setSelectedSchedule({ ...selectedSchedule });
  };

  const save = () => {
    setLoading(true);
    setTimeout(async () => {
      // Making all this logic async to allow loading spinner to change
      plan = plan || {};
      plan.type = type;
      plan.title = title;
      plan.subtitle = subtitle;
      plan.active = active;
      plan.description = description;
      plan.policy = policy;
      plan.resources = resources;
      plan.pricingRanges = pricingRanges;
      plan.duration = duration;
      plan.dateRange = dateRange;
      plan.minDateRange = minDateRange;
      plan.maxDateRange = maxDateRange;
      plan.dateRangeUnit = dateRangeUnit;
      plan.dateRangeBasis = dateRangeBasis;
      plan.schedules = schedules.sort(sortTime);
      if (!plan.id) {
        // Creating new plan
        service.plans = service.plans || [];
        service.plans.push({
          id: uuid.v4(),
          ...plan
        });
      }
      await (API.crud({
        operation: '_update',
        table: 'hotels',
        query: context[key]
      })).finally(() => setLoading(false));
      navigation.navigate('ContentDetail', {
        ...route.params,
        groups,
        group,
        service
      });
    }, 0);
  };

  const removePlan = () => {
    return Toast.show({
      type: 'action',
      autoHide: false,
      text1: translate('CONFIRM_ACTION'),
      text2: translate('CONFIRM_ACTION_DESCRIPTION'),
      props: {
        actions: [{
          text: translate('NO'),
          onPress: Toast.hide
        }, {
          text: translate('YES'),
          class: 'error',
          onPress: async () => {
            setLoading(true);
            const idx = service.plans?.findIndex(p => p.id === plan.id);
            service.plans?.splice(idx, 1);
            await (API.crud({
              operation: '_update',
              table: 'hotels',
              query: context[key]
            })).finally(() => setLoading(false));
            Toast.hide();
            navigation.navigate('ContentDetail', {
              ...route.params,
              groups,
              group,
              service
            });
          }
        }]
      }
    });
  };

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <TouchableOpacity style={[Theme.styles.row, Theme.styles.alignCenterCenter, Platform.OS === 'web' ? Theme.styles.marginRight16 : null]} onPress={save} disabled={loading}>
          { loading ? (
            <ActivityIndicator color={Theme.palette.primaryContrast} />
          ) : (
            <MaterialCommunityIcons name='check' color={Theme.palette.primaryContrast} size={25} />
          )}
        </TouchableOpacity>
      ),
    });
  }, [
    navigation,
    loading,
    type,
    title,
    subtitle,
    active,
    description,
    policy,
    resources,
    pricingRanges,
    duration,
    dateRange,
    minDateRange,
    maxDateRange,
    dateRangeUnit,
    dateRangeBasis,
    schedules
  ]);

  const setPricingRangeValue = (item, key) => {
    return (value) => {
      if (key === 'start' || key === 'end') {
        //value = moment(value).format('LL').replace(/\d{4}|, /g, '');
        if (item.start > item.end) {
          item.end = null;
        }
      }
      item[key] = value;
      setSelectedPricingRange({
        ...selectedPricingRange
      });
    };
  };

  const _setMaxDateRange = (maxDateRange) => {
    if (!maxDateRange || isNaN(parseFloat(maxDateRange))) maxDateRange = null;
    return setMaxDateRange(maxDateRange);
  };

  const toggleResource = (id) => {
    const idx = resources.indexOf(id);
    if (idx === -1) {
      resources.push(id);
    } else {
      resources.splice(idx, 1);
    }
    setResources([...resources]);
  };

  const openNewResource = () => {
    assignResourcesBottomSheetModalRef?.current?.dismiss();
    return navigation.navigate('ContentResourceDetail', {
      ...route.params,
      navigateTo: 'ContentPlan',
      config: context.hotelConfig?.data,
    });
  };

  const openPricingRange = (range = DEFAULT_PRICING_RANGE) => {
    setSelectedPricingRange(range);
    pricingBottomSheetModalRef?.current?.present();
  };

  const openSchedule = (schedule = {...DEFAULT_SCHEDULE}) => {
    setSelectedSchedule(schedule);
    scheduleBottomSheetModalRef?.current?.present();
  };

  // renders
  const renderResource = (resourceId, idx) => {
    const resource = context.hotelConfig?.data?.resources?.find(r => r.id === resourceId);
    return (
      <SelectorComponent
        key={idx}
        onPress={() => assignResourcesBottomSheetModalRef?.current?.present()}
        selected={false}
        title={resource?.name?.[locale]}
        subtitle={ResourceTypes[resource?.type]?.label}
        value={'✓'}
      />
    );
  };

  const renderBackdrop = useCallback(props => (
    <BottomSheetBackdrop
      {...props}
      disappearsOnIndex={-1}
      appearsOnIndex={0}
    />
  ), []);

  const renderAssignResourcesFooter = useCallback(props => {
    return (
      <BottomSheetFooter {...props}>
        <View style={Theme.styles.row}>
          <View style={Theme.styles.flex}>
            <ButtonComponent safeArea={!visible} text={translate('CLOSE')} onPress={() => assignResourcesBottomSheetModalRef.current?.dismiss()} />
          </View>
        </View>
      </BottomSheetFooter>
    );
  }, [visible]);

  const savePricingRange = () => {
    if (!selectedPricingRange.start || !selectedPricingRange.end) {
      return Toast.show({
        type: 'error',
        text1: translate('FORM_SUBMIT_ERROR'),
        text2: translate('FORM_SUBMIT_ERROR_DESCRIPTION')
      });
    }
    if (!selectedPricingRange.id) {
      selectedPricingRange.id = uuid.v4();
      pricingRanges.push(selectedPricingRange);
    } else {
      const idx = pricingRanges.findIndex(r => r.id === selectedPricingRange.id);
      pricingRanges[idx] = selectedPricingRange;
    }
    setPricingRanges([...pricingRanges]);
    setSelectedPricingRange(DEFAULT_PRICING_RANGE);
    pricingBottomSheetModalRef.current?.dismiss();
  };

  const deletePricingRange = () => {
    const idx = pricingRanges.indexOf(selectedPricingRange);
    pricingRanges.splice(idx, 1);
    setPricingRanges([...pricingRanges]);
    setSelectedPricingRange(DEFAULT_PRICING_RANGE);
    pricingBottomSheetModalRef.current?.dismiss();
  };

  const saveSchedule = () => {
    if (!selectedSchedule.id) {
      selectedSchedule.id = uuid.v4();
      schedules.push(selectedSchedule);
    } else {
      const idx = schedules.findIndex(r => r.id === selectedSchedule.id);
      schedules[idx] = selectedSchedule;
    }
    setSchedules([...schedules]);
    setSelectedSchedule(DEFAULT_SCHEDULE);
    scheduleBottomSheetModalRef.current?.dismiss();
  };

  const deleteSchedule = () => {
    const idx = schedules.indexOf(selectedSchedule);
    schedules.splice(idx, 1);
    setSchedules([...schedules]);
    setSelectedSchedule(DEFAULT_SCHEDULE);
    scheduleBottomSheetModalRef.current?.dismiss();
  };

  const addPricingRangeGroup = () => {
    selectedPricingRange.groups = selectedPricingRange.groups || [];
    let lastMax = 0;
    let lastPrice = selectedPricingRange.groupPrice;
    if (selectedPricingRange.groups.length) {
      lastMax = selectedPricingRange.groups[selectedPricingRange.groups.length - 1].max || lastMax;
      lastPrice = selectedPricingRange.groups[selectedPricingRange.groups.length - 1].price || lastPrice;
    }
    selectedPricingRange.groups.push({
      min: lastMax + 1,
      max: lastMax + 2,
      price: lastPrice
    });
    setSelectedPricingRange({ ...selectedPricingRange });
  };

  const deletePricingRangeGroup = (idx) => {
    selectedPricingRange.groups.splice(idx, 1);
    setSelectedPricingRange({ ...selectedPricingRange });
  };

  // Renders
  const renderPricingRangeFooter = useCallback(props => {
    return (
      <BottomSheetFooter {...props}>
        <View style={Theme.styles.row}>
          <View style={Theme.styles.flex}>
            <ButtonComponent safeArea={!visible} text={translate('SAVE')} onPress={savePricingRange} />
          </View>
        </View>
      </BottomSheetFooter>
    );
  }, [visible, selectedPricingRange]);

  const renderScheduleFooter = useCallback(props => {
    return (
      <BottomSheetFooter {...props}>
        <View style={Theme.styles.row}>
          <View style={Theme.styles.flex}>
            <ButtonComponent safeArea={!visible} text={translate('SAVE')} onPress={saveSchedule} />
          </View>
        </View>
      </BottomSheetFooter>
    );
  }, [visible, selectedSchedule]);

  return (
    <View style={[Theme.styles.height, Theme.styles.column, Theme.styles.alignStretchCenter, Theme.XL ? [Theme.styles.webContainer, Theme.styles.webContainerWrapper] : null]}>
      <ScrollView style={Theme.styles.height} contentContainerStyle={[Theme.styles.column, Theme.styles.alignStretchStart]}>
        <HeaderComponent title={plan.id ? `${translate('EDIT')} "${plan.title[langs[0]]}"` : translate('NEW_PLAN')} subtitleComponent={(
          <Switch
            style={Theme.styles.activeButton}
            onValueChange={setActive}
            value={active}
          />
        )}/>
        <View style={Theme.styles.paddingHorizontal8}>
          <Picker
            placeholder={{}}
            value={type}
            onValueChange={setType}
            label={translate('TYPE')}
            items={[{
              label: translate('DEFAULT'),
              value: 'default'
            }, {
              label: translate('MENU'),
              value: 'menu'
            }, {
              label: translate('BUFFET'),
              value: 'buffet'
            }]}
          >
            <View style={[Theme.styles.row, Theme.styles.paddingBottom8, Theme.styles.pickerInput]}>
              <View>
                <Text style={Theme.styles.inputLabel}>{translate('TYPE')}</Text>
                <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.letterSpacing1, Theme.styles.paddingLeft8]}>{translate(type.toUpperCase() || 'DEFAULT')}</Text>
              </View>
            </View>
          </Picker>
        </View>
        <LocalizedInput value={plan.title} onChange={setTitle} label={translate('TITLE')} required />
        <LocalizedInput value={plan.subtitle} onChange={setSubtitle} label={translate('SUBTITLE')} />
        <LocalizedInput value={plan.description} onChange={setDescription} label={translate('DESCRIPTION')} textarea recommendedMax={315} hint={translate('OVERWRITES_SERVICE_DESCRIPTION')} />
        <LocalizedInput value={plan.policy} onChange={setPolicy} label={translate('SERVICE_POLICY')} textarea hint={translate('OVERWRITES_SERVICE_POLICY')} />
        { type === 'default' ? (
          <View>
            <View style={Theme.styles.padding8}>
              <HeaderComponent title={translate('RESOURCES')} actionText={translate('ASSIGN_RESOURCES')} onPress={() => assignResourcesBottomSheetModalRef?.current?.present()} outlined />
            </View>
            { resources.length ? (
              <View style={Theme.styles.paddingHorizontal16}>
                { resources.map(renderResource) }
              </View>
            ) : (
              <View style={[styles.media, Theme.styles.flex, Theme.styles.background, Theme.styles.row, Theme.styles.alignCenterCenter]}>
                <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.textCenter]}>
                  { translate('NO_RESOURCES_ASSIGNED_YET') }
                </Text>
              </View>
            )}
            <View style={Theme.styles.padding8}>
              <HeaderComponent title={translate('PRICING')} actionText={translate('ADD_PRICING_RANGE')} onPress={() => openPricingRange()} outlined />
            </View>
            { pricingRanges.length ? (
              <View style={Theme.styles.paddingHorizontal16}>
                { pricingRanges.map((range, idx) => (
                  <SelectorComponent
                    key={idx}
                    onPress={() => openPricingRange(range)}
                    selected={false}
                    title={moment(range.start).format('ll') + ' - ' + moment(range.end).format('ll')}
                    subtitle={`${translate('RANGE_BY')}: ${translate(range.basis === 'age' ? 'AGE' : 'GROUP_SIZE')}`}
                    value={getPriceString(getRangePrice(range, undefined, true))}
                  />
                ))}
              </View>
            ) : (
              <View style={[styles.media, Theme.styles.flex, Theme.styles.background, Theme.styles.row, Theme.styles.alignCenterCenter]}>
                <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.textCenter]}>
                  { translate('NO_PRICING_DEFINED_YET') }
                </Text>
              </View>
            )}
          </View>
        ) : null}
        <View style={Theme.styles.padding8} />
        <View style={Theme.styles.padding8}>
          <HeaderComponent title={translate('DATE_AND_TIME_SETTINGS')} />
          <InlineInputComponent label={`${translate('DURATION')} (${translate('MIN')})`} value={duration} onChange={setDuration} keyboardType='numeric' inputComponent={TextInput} />
          { type === 'default' ? (
            <View style={[Theme.styles.row, Theme.styles.alignCenterSpaceBetween, Theme.styles.paddingLeft8, Theme.styles.paddingRight8, Theme.styles.paddingBottom8]}>
              <TouchableOpacity style={[Theme.styles.row, Theme.styles.alignStartstartStart]} onPress={() => setDateRange(!dateRange)}>
                <CheckBox
                  onValueChange={() => setDateRange(!dateRange)}
                  color={dateRange ? Theme.palette.accent : null }
                  style={Theme.styles.checkbox}
                  value={dateRange}
                />
                <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.paddingLeft8]}>
                  { translate('DATE_RANGE') }
                </Text>
              </TouchableOpacity>
            </View>
          ) : null}
          { dateRange ? (
            <View>
              <InlineInputComponent label={translate('MINIMUM_RANGE')} value={minDateRange} onChange={setMinDateRange} keyboardType='numeric' inputComponent={TextInput} />
              <InlineInputComponent label={translate('MAXIMUM_RANGE')} value={maxDateRange} defaultValue='' onChange={_setMaxDateRange} keyboardType='numeric' inputComponent={TextInput} />
              <Picker
                placeholder={{}}
                value={dateRangeUnit || 'night'}
                onValueChange={setDateRangeUnit}
                label={translate('DATE_RANGE_UNIT')}
                items={[{
                  label: translate('DAY(S)'),
                  value: 'day'
                }, {
                  label: translate('NIGHT(S)'),
                  value: 'night'
                }]}
              >
                <View style={[Theme.styles.row, Theme.styles.paddingBottom8, Theme.styles.pickerInput]}>
                  <View>
                    <Text style={Theme.styles.inputLabel}>{translate('DATE_RANGE_UNIT')}</Text>
                    <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.letterSpacing1, Theme.styles.paddingLeft8]}>{translate(dateRangeUnit.toUpperCase() + '(S)')}</Text>
                  </View>
                </View>
              </Picker>
              <Picker
                placeholder={{}}
                value={dateRangeBasis || 'day'}
                onValueChange={setDateRangeBasis}
                label={translate('PRICE_PER')}
                items={[{
                  label: translate('DAY/NIGHT'),
                  value: 'day'
                }, {
                  label: translate('PERIOD'),
                  value: 'period'
                }]}
              >
                <View style={[Theme.styles.row, Theme.styles.paddingBottom8, Theme.styles.pickerInput]}>
                  <View>
                    <Text style={Theme.styles.inputLabel}>{translate('PRICE_PER')}</Text>
                    <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.letterSpacing1, Theme.styles.paddingLeft8]}>{translate(DATE_RANGE_BASIS_DICT[dateRangeBasis] || 'DAY/NIGHT')}</Text>
                  </View>
                </View>
              </Picker>
              <View style={Theme.styles.padding8} />
            </View>
          ) : null}
        </View>
        <View style={Theme.styles.padding8} >
          <HeaderComponent title={translate('SCHEDULES')} actionText={translate('ADD_SCHEDULE')} onPress={() => openSchedule()} outlined />
        </View>
        { schedules.length ? (
          <View style={Theme.styles.paddingHorizontal16}>
            { schedules.sort(sortTime).map((schedule, idx) => (
              <SelectorComponent
                key={idx}
                onPress={() => openSchedule(schedule)}
                selected={false}
                title={moment.utc(schedule.startTime).format('hh:mm A')}
                subtitle={
                  moment.weekdaysShort().
                    filter((day, idx) => schedule.weekdays.includes(idx)).
                    map(day => day).
                    join(', ')
                }
                value={getPriceString(schedule.baseAdults)}
              />
            ))}
          </View>
        ) : (
          <View style={[styles.media, Theme.styles.flex, Theme.styles.background, Theme.styles.row, Theme.styles.alignCenterCenter]}>
            <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.textCenter]}>
              { translate('NO_SCHEDULE_SLOTS_YET') }
            </Text>
          </View>
        )}
        { plan.id ? (
          <View style={[Theme.styles.padding8, Theme.styles.paddingTop16]}>
            <ButtonComponent text={translate('DELETE_PLAN')} type='error' onPress={removePlan} outlined />
          </View>
        ) : null}

        {/* Resources Bottom Sheet */}
        <BottomSheetModal
          ref={assignResourcesBottomSheetModalRef}
          style={Theme.XL ? [Theme.styles.webContainer, Theme.styles.padding0] : null}
          index={0}
          snapPoints={snapPoints}
          enablePanDownToClose={true}
          backdropComponent={renderBackdrop}
          footerComponent={renderAssignResourcesFooter}
        >
          <BottomSheetScrollView>
            <View style={[Theme.styles.paddingLeft8, Theme.styles.paddingRight8, Theme.styles.paddingBottom80]}>
              <HeaderComponent
                title={translate('RESOURCES')}
                actionText={translate('NEW_RESOURCE')}
                onPress={openNewResource}
                outlined
              />
              <View style={Theme.styles.padding16}>
                <ResourcesListComponent navigation={navigation} route={route} nested={true} renderItem={({item}) => (
                  <SelectorComponent
                    onPress={() => toggleResource(item._resource.id)}
                    selected={resources.includes(item._resource.id)}
                    title={item._resource.name[locale]}
                    subtitle={isResourceAvailable(item._resource) ? translate('AVAILABLE') : translate('NOT_AVAILABLE')}
                    value={resources.includes(item._resource.id) ? translate('ASSIGNED') : '' }
                  />
                )} />
              </View>
            </View>
          </BottomSheetScrollView>
        </BottomSheetModal>

        {/* Pricing Bottom Sheet */}
        <BottomSheetModal
          ref={pricingBottomSheetModalRef}
          style={Theme.XL ? [Theme.styles.webContainer, Theme.styles.padding0] : null}
          index={0}
          snapPoints={snapPoints}
          enablePanDownToClose={true}
          backdropComponent={renderBackdrop}
          footerComponent={renderPricingRangeFooter}
        >
          <BottomSheetScrollView>
            <View style={[Theme.styles.paddingHorizontal8, Theme.styles.paddingBottom80]}>
              <HeaderComponent title={translate('PRICING')} />
              <Picker
                placeholder={{}}
                value={selectedPricingRange.basis}
                onValueChange={basis => setSelectedPricingRange({...selectedPricingRange, basis})}
                label={translate('RANGE_BY')}
                items={[{
                  label: translate('AGE'),
                  value: 'age'
                }, {
                  label: translate('GROUP_SIZE'),
                  value: 'groupSize'
                }]}
              >
                <View style={[Theme.styles.row, Theme.styles.paddingBottom8, Theme.styles.pickerInput]}>
                  <View>
                    <Text style={Theme.styles.inputLabel}>{translate('RANGE_BY')}</Text>
                    <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.letterSpacing1, Theme.styles.paddingLeft8]}>{translate(selectedPricingRange.basis === 'age' ?  'AGE' : 'GROUP_SIZE')}</Text>
                  </View>
                </View>
              </Picker>
              <View style={Theme.styles.padding8} />
              { selectedPricingRange.basis === 'age' ? (
                <View>
                  <HeaderComponent title={translate('BASE_PRICE')} />
                  <View style={[Theme.styles.row, Theme.styles.padding8, Theme.styles.alignCenterSpaceBetween]}>
                    <Text style={[Theme.styles.text, Theme.styles.letterSpacing1, Theme.styles.flex, Theme.styles.textRight, Platform.OS === 'web' ? styles.inputLabelWeb : null]}>
                      {translate('ADULTS')}
                    </Text>
                    <Text style={[Theme.styles.text, Theme.styles.letterSpacing1, Theme.styles.flex, Theme.styles.textRight, Platform.OS === 'web' ? styles.inputLabelWeb : null]}>
                      {translate('KIDS')}
                    </Text>
                    <Text style={[Theme.styles.text, Theme.styles.letterSpacing1, Theme.styles.flex, Theme.styles.textRight, Platform.OS === 'web' ? styles.inputLabelWeb : null]}>
                      {translate('INFANTS')}
                    </Text>
                  </View>
                  <View style={[Theme.styles.row, Theme.styles.padding8, Theme.styles.alignCenterSpaceBetween]}>
                    <InlineInputComponent value={selectedPricingRange.baseAdults} onChange={setPricingRangeValue(selectedPricingRange, 'baseAdults')} keyboardType='numeric' />
                    <InlineInputComponent value={selectedPricingRange.baseKids} onChange={setPricingRangeValue(selectedPricingRange, 'baseKids')} keyboardType='numeric' />
                    <InlineInputComponent value={selectedPricingRange.baseInfants} onChange={setPricingRangeValue(selectedPricingRange, 'baseInfants')} keyboardType='numeric' />
                  </View>
                  <HeaderComponent title={translate('ADDITIONAL_ATTENDEES')} />
                  <View style={[Theme.styles.row, Theme.styles.padding8, Theme.styles.alignCenterSpaceBetween]}>
                    <Text style={[Theme.styles.text, Theme.styles.letterSpacing1, Theme.styles.flex, Theme.styles.textRight, Platform.OS === 'web' ? styles.inputLabelWeb : null]}>
                      {translate('ADULTS')}
                    </Text>
                    <Text style={[Theme.styles.text, Theme.styles.letterSpacing1, Theme.styles.flex, Theme.styles.textRight, Platform.OS === 'web' ? styles.inputLabelWeb : null]}>
                      {translate('KIDS')}
                    </Text>
                    <Text style={[Theme.styles.text, Theme.styles.letterSpacing1, Theme.styles.flex, Theme.styles.textRight, Platform.OS === 'web' ? styles.inputLabelWeb : null]}>
                      {translate('INFANTS')}
                    </Text>
                  </View>
                  <View style={[Theme.styles.row, Theme.styles.padding8, Theme.styles.alignCenterSpaceBetween]}>
                    <InlineInputComponent value={selectedPricingRange.extraAdult} onChange={setPricingRangeValue(selectedPricingRange, 'extraAdult')} keyboardType='numeric' />
                    <InlineInputComponent value={selectedPricingRange.extraKid} onChange={setPricingRangeValue(selectedPricingRange, 'extraKid')} keyboardType='numeric' />
                    <InlineInputComponent value={selectedPricingRange.extraInfant} onChange={setPricingRangeValue(selectedPricingRange, 'extraInfant')} keyboardType='numeric' />
                  </View>
                </View>
              ) : (
                <View>
                  <Picker
                    placeholder={{}}
                    value={selectedPricingRange.groupPriceBasis}
                    onValueChange={groupPriceBasis => setSelectedPricingRange({ ...selectedPricingRange, groupPriceBasis })}
                    label={translate('PRICE_BASIS')}
                    items={[{
                      label: translate('PERSON'),
                      value: 'person'
                    }, {
                      label: translate('WHOLE_GROUP'),
                      value: 'group'
                    }]}
                  >
                    <View style={[Theme.styles.row, Theme.styles.paddingBottom8, Theme.styles.pickerInput]}>
                      <View>
                        <Text style={Theme.styles.inputLabel}>{translate('PRICE_BASIS')}</Text>
                        <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.letterSpacing1, Theme.styles.paddingLeft8]}>{translate(selectedPricingRange.groupPriceBasis === 'person' ?  'PERSON' : 'WHOLE_GROUP')}</Text>
                      </View>
                    </View>
                  </Picker>
                  <View style={Theme.styles.padding8} />
                  <InlineInputComponent label={translate('DEFAULT_PRICE') + ' (US$)'} value={selectedPricingRange.groupPrice} onChange={value => setSelectedPricingRange({...selectedPricingRange, groupPrice: value})} keyboardType='numeric' />
                  <HeaderComponent title={translate('GROUP_RANGE_PRICES')} actionText={translate('ADD')} onPress={() => addPricingRangeGroup()} outlined />
                  { selectedPricingRange.groups?.length ? (
                    <View style={[Theme.styles.row, Theme.styles.padding8, Theme.styles.alignCenterSpaceBetween]}>
                      <Text style={[Theme.styles.text, Theme.styles.letterSpacing1, Theme.styles.flex, Theme.styles.textRight]}>
                        {translate('MINIMUM')}
                      </Text>
                      <Text style={[Theme.styles.text, Theme.styles.letterSpacing1, Theme.styles.flex, Theme.styles.textRight]}>
                        {translate('MAXIMUM')}
                      </Text>
                      <Text style={[Theme.styles.text, Theme.styles.letterSpacing1, Theme.styles.flex, Theme.styles.textRight]}>
                        {translate('PRICE')} (US$)
                      </Text>
                      <View style={styles.actionColumn}></View>
                    </View>
                  ) : null }
                  { selectedPricingRange.groups?.map((group, idx) => (
                    <View key={idx} style={[Theme.styles.row, Theme.styles.padding8, Theme.styles.alignCenterSpaceBetween]}>
                      <InlineInputComponent value={group.min} onChange={setPricingRangeValue(group, 'min')} keyboardType='numeric' />
                      <InlineInputComponent value={group.max} onChange={setPricingRangeValue(group, 'max')} keyboardType='numeric' />
                      <InlineInputComponent value={group.price} onChange={setPricingRangeValue(group, 'price')} keyboardType='numeric' />
                      <TouchableOpacity style={styles.actionColumn} onPress={() => deletePricingRangeGroup(idx)}>
                        <MaterialCommunityIcons name='delete' color={Theme.palette.text} size={20} />
                      </TouchableOpacity>
                    </View>
                  ))}
                </View>
              )}
              <View style={Theme.styles.padding8} />
              <HeaderComponent title={translate('DATE_RANGE')} />
              <View style={[Theme.styles.row, Theme.styles.padding8, Theme.styles.alignCenterSpaceBetween]}>
                <Text style={[Theme.styles.text, Theme.styles.letterSpacing1, Theme.styles.flex, Theme.styles.paddingRight6]}>
                  {translate('START_DATE')}*
                </Text>
                <Text style={[Theme.styles.text, Theme.styles.letterSpacing1, Theme.styles.flex, Theme.styles.paddingLeft6]}>
                  {translate('END_DATE')}*
                </Text>
              </View>
              <View style={[Theme.styles.row, Theme.styles.padding8, Theme.styles.alignCenterSpaceBetween]}>
                <View style={[Theme.styles.flex, Theme.styles.paddingRight6]}>
                  <DatePickerComponent
                    value={selectedPricingRange.start}
                    onChange={setPricingRangeValue(selectedPricingRange, 'start')}
                  />
                </View>
                <View style={[Theme.styles.flex, Theme.styles.paddingLeft6]}>
                  <DatePickerComponent
                    minDate={selectedPricingRange.start}
                    value={selectedPricingRange.end}
                    onChange={setPricingRangeValue(selectedPricingRange, 'end')}
                  />
                </View>
              </View>
              { selectedPricingRange.id ? (
                <View style={[Theme.styles.padding8, Theme.styles.paddingTop16]}>
                  <ButtonComponent text={translate('DELETE_PRICING_RANGE')} type='error' onPress={deletePricingRange} outlined />
                </View>
              ) : null}
            </View>
          </BottomSheetScrollView>
        </BottomSheetModal>

        {/* Schedule Bottom Sheet */}
        <BottomSheetModal
          ref={scheduleBottomSheetModalRef}
          style={Theme.XL ? [Theme.styles.webContainer, Theme.styles.padding0] : null}
          index={0}
          snapPoints={['50%']}
          enablePanDownToClose={true}
          backdropComponent={renderBackdrop}
          footerComponent={renderScheduleFooter}
        >
          <BottomSheetScrollView>
            <View style={[Theme.styles.paddingHorizontal8, Theme.styles.paddingBottom80]}>
              <HeaderComponent title={translate('SCHEDULE')} />
              <HeaderComponent title={translate('OPERATING_DAYS')} />
              <View style={[Theme.styles.row, Theme.styles.alignCenterSpaceBetween, Theme.styles.paddingLeft8, Theme.styles.paddingRight8]}>
                { moment.weekdaysMin().map((day, idx) => (
                  <TouchableOpacity key={idx} style={[Theme.styles.row, Theme.styles.alignCenterCenter, styles.weekday, (selectedSchedule?.weekdays || []).includes(idx) ? styles.weekdayActive : null]} onPress={() => toggleWeekDay(idx)}>
                    <Text>{day}</Text>
                  </TouchableOpacity>
                ))}
              </View>
              <View style={[Theme.styles.paddingTop16, Theme.styles.row]} >
                <Text style={[Theme.styles.inputLabel, Theme.styles.flex, Theme.styles.paddingRight16]}>
                  {translate('START_HOUR')}
                </Text>
                <TimePicker value={selectedSchedule.startTime} onChange={startTime => setSelectedSchedule({...selectedSchedule, startTime})} />
              </View>
              { selectedSchedule.id ? (
                <View style={[Theme.styles.padding8, Theme.styles.paddingTop16]}>
                  <ButtonComponent text={translate('DELETE_SCHEDULE')} type='error' onPress={deleteSchedule} outlined />
                </View>
              ) : null}
            </View>
          </BottomSheetScrollView>
        </BottomSheetModal>
      </ScrollView>
    </View>
  );
};
