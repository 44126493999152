import React, {
  useEffect,
  useCallback,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  View,
  ActivityIndicator,
  StyleSheet,
  Text,
  TouchableOpacity
} from 'react-native';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import {
  BottomSheetBackdrop,
  BottomSheetFooter,
  BottomSheetTextInput,
  BottomSheetModal,
} from '@gorhom/bottom-sheet';
import { BottomSheetScrollView } from '../common/libs/bottom-sheet';
import useKeyboard from '@rnhooks/keyboard';

import * as API from './api.service';
import { Theme } from '../../theme';
import { translate } from '../../lang';
import { HeaderComponent } from './header.component';
import { ButtonComponent } from './button.component';

const styles = StyleSheet.create({
  input: {
    ...Theme.styles.padding8,
    ...Theme.styles.text,
    ...Theme.styles.textDark,
    borderBottomColor: Theme.palette.background,
    borderBottomWidth: 1,
    paddingTop: 0
  },
  textInput: {
    marginTop: 8,
    marginBottom: 10,
    borderRadius: 2,
    fontSize: 16,
    lineHeight: 20,
    padding: 8,
    paddingRight: 26,
    borderWidth: 1,
    borderColor: Theme.palette.background,
    color: Theme.palette.textDark
  },
  translateButton: {
    marginLeft: 16,
    marginRight: 8
  },
  recommendedMax: {
    marginTop: -4
  },
  clearButton: {
    left: -4,
    marginLeft: -20
  }
});

export const LocalizedInput = ({
  label,
  value,
  disabled,
  languages,
  onChange,
  textarea,
  recommendedMax,
  style,
  hint,
  hideLabel,
  required
}) => {
  const [ visible ] = useKeyboard();
  const bottomSheetModalRef = useRef(null);
  const langs = languages || process.env.LANGS.split(',');
  const [loading, setLoading] = useState({});
  const [val, setVal] = useState(value || {});
  const done = !langs.filter(l => !val[l]).length;
  const any = langs.find(l => val[l]);
  const snapPoints = useMemo(() => ['50%'], []);

  useEffect(() => {
    if (value && JSON.stringify(value) !== JSON.stringify(val)) {
      setVal(value);
    }
  }, [value]);

  const edit = () => {
    bottomSheetModalRef.current?.present();
  };
  const save = () => {
    onChange(val);
    bottomSheetModalRef.current?.dismiss();
  };
  const autoTranslate = async (lang) => {
    setLoading({
      ...loading,
      [lang]: true
    });
    const response = await API.getTranslation({
      lang,
      text: val.en
    });
    setVal({
      ...val,
      [lang]: response.TranslatedText
    });
    setLoading({
      ...loading,
      [lang]: false
    });
  };

  const translateMissing = async () => {
    setLoading({...loading, all: true});
    for (let lang of langs) {
      if (!val?.[lang]) {
        const response = await API.getTranslation({
          lang,
          text: val.en
        });
        val[lang] = response.TranslatedText;
      }
    }
    setVal({...val});
    setLoading({});
  };

  // renders
  const renderBackdrop = useCallback(props => (
    <BottomSheetBackdrop
      {...props}
      disappearsOnIndex={-1}
      appearsOnIndex={0}
    />
  ), []);

  const renderFooter = useCallback(props => {
    return (
      <BottomSheetFooter {...props} >
        <ButtonComponent safeArea={!visible} text={translate('SAVE')} onPress={save} />
      </BottomSheetFooter>
    );
  }, [visible, val]);

  return (
    <View style={style?.container}>
      <TouchableOpacity onPress={edit} disabled={disabled} style={[Theme.styles.width, Theme.styles.padding8, disabled ? Theme.styles.disabled : null]}>
        { !hideLabel && label ? (
          <View style={[Theme.styles.row, Theme.styles.alignCenterStart]}>
            <Text style={Theme.styles.inputLabel}>
              { label }{ required ? '*' : '' }
            </Text>
            { any ? <MaterialCommunityIcons name={done ? 'check-all' : 'close'} color={done ? Theme.palette.success : Theme.palette.error} size={20} /> : null }
          </View>
        ) : null }
        <View style={[styles.input, Theme.styles.row, Theme.styles.alignCenterSpaceBetween]}>
          { (!label || hideLabel) && any ? (
            <MaterialCommunityIcons name={done ? 'check-all' : 'close'} color={done ? Theme.palette.success : Theme.palette.error} size={20} style={Theme.styles.paddingRight8} />
          ) : null}
          <Text style={[Theme.styles.text, Theme.styles.flex, Theme.styles.letterSpacing1, Theme.styles.paddingRight7, val[langs[0]] ? Theme.styles.textDark : null ]} numberOfLines={1}>
            { val[langs[0]] || translate('TAP_TO_EDIT') }
          </Text>
          { !disabled ? (
            <MaterialCommunityIcons name="pencil" color={Theme.palette.text} size={20} />
          ) : null
          }
        </View>
        { hint ? (
          <Text style={[Theme.styles.text, Theme.styles.letterSpacing0, Theme.styles.fontSize12, Theme.styles.textRight, Theme.styles.paddingTop4]}>{hint}</Text>
        ): null
        }
      </TouchableOpacity>
      <BottomSheetModal
        ref={bottomSheetModalRef}
        style={Theme.XL ? [Theme.styles.webContainer, Theme.styles.padding0] : null}
        index={0}
        snapPoints={snapPoints}
        enablePanDownToClose={true}
        backdropComponent={renderBackdrop}
        footerComponent={renderFooter}
      >
        <BottomSheetScrollView>
          <View style={Theme.styles.paddingLeft8}>
            <HeaderComponent title={`${translate('EDIT_FIELD')} "${ label }"`} />
          </View>
          <View style={[Theme.styles.paddingLeft16, Theme.styles.paddingRight16, Theme.styles.paddingBottom60]}>
            { langs.map((lang, idx) => (
              <View key={idx} style={Theme.styles.paddingTop8}>
                <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.letterSpacing1]}>{translate(`LANG_${lang.toUpperCase()}`)}</Text>
                <View style={[Theme.styles.row, Theme.styles.alignCenterStart]}>
                  <BottomSheetTextInput style={[Theme.styles.flex, styles.textInput]} onChangeText={text => setVal({...val, [lang]: text})} value={val[lang]} multiline={textarea} numberOfLines={textarea ? 10 : 1}/>
                  { val?.[lang]?.length ? (
                    <TouchableOpacity onPress={() => setVal({...val, [lang]: ''})} style={styles.clearButton}>
                      <MaterialCommunityIcons name='close' color={Theme.palette.text} size={20} />
                    </TouchableOpacity>
                  ) : null}
                  { idx === 0 ? (
                    <View style={Theme.styles.paddingLeft16}>
                      <ButtonComponent onPress={translateMissing} disabled={!val?.[lang]?.length || loading.all} loading={loading.all} text={Theme.XL ? translate('TRANSLATE_MISSING') : ''} inline outlined>
                        { !Theme.XL ? (
                          <MaterialCommunityIcons name='translate' color={Theme.palette.accent} size={20} />
                        ) : null}
                      </ButtonComponent>
                    </View>
                  ) : null }
                  { idx !== 0 && val.en?.length ?
                    loading[lang] || loading.all ? (
                      <ActivityIndicator color={Theme.palette.text} style={styles.translateButton} />
                    ) : (
                      <TouchableOpacity onPress={() => autoTranslate(lang)}>
                        <MaterialCommunityIcons name='translate' color={Theme.palette.textDark} size={20} style={styles.translateButton} />
                      </TouchableOpacity>
                    )
                    : null
                  }
                </View>
                { recommendedMax ? (
                  <Text style={[Theme.styles.text, Theme.styles.letterSpacing0, Theme.styles.fontSize10, Theme.styles.textRight, styles.recommendedMax]}>{translate('CHAR_COUNT', {count: (val[lang] || '').length, recommendedMax})}</Text>
                ): null
                }
              </View>
            ))}
          </View>
        </BottomSheetScrollView>
      </BottomSheetModal>
    </View>
  );
};

