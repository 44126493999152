import moment from 'moment';
import React, {
  useEffect,
  useContext,
  useCallback,
  useRef,
  useState,
} from 'react';
import {
  SectionList,
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
} from 'react-native';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { useFocusEffect } from '@react-navigation/native';
import Carousel from 'react-native-snap-carousel';

import { CarouselItem } from '../common/tile';
import { Theme } from '../../theme';
import { Context } from '../Context';
import { translate, getCurrentLocale } from '../../lang';
import { getBookingContext, getDurationString } from '../common/utils.service';
import { ListItemComponent } from '../common/list-item.component';
import { AnimateService } from '../common/animate.service';
import { StatusLabelComponent } from '../common/status-label.component';
import { BookingDetailComponent, ICONS } from '../bookings/detail';
import { HeaderComponent } from '../common/header.component';
import { DatePickerComponent } from '../common/datepicker.component';

const styles = StyleSheet.create({
  container: {
    backgroundColor: Theme.palette.modeBackgroundColor,
  },
  listHeader: {
    container: {
      paddingVertical: 10,
      height: 40,
      backgroundColor: Theme.palette.modeBackgroundColor,
    },
    title: {
      color: 'grey',
      textTransform: 'none',
      textAlign: 'center',
      fontSize: 12,
      fontWeight: 'bold',
      letterSpacing: 1
    }
  },
  publicBookingContainer: {
    margin: 16,
    borderRadius: 10,
    shadowColor: Theme.palette.backgroundDark,
    shadowOffset: {width: 0, height: 1},
    shadowOpacity: 1,
    shadowRadius: 5,
  },
  carouselItem: {
    media: {
      borderTopRightRadius: 5,
      borderTopLeftRadius: 5
    }
  },
  publicBooking: {
    container: {
      marginTop: -1,
      borderBottomEndRadius: 10,
      borderBottomStartRadius: 10,
    }
  },
  publicBookingFirst: {
    container: {
      borderBottomEndRadius: 10,
      borderBottomStartRadius: 10,
    }
  }
});

export const BookingsListComponent = ({
  bookings,
  getBookings,
  navigation,
  onBookingUpdated,
  route,
}) => {
  const context = useContext(Context);
  const sectionListRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [booking, setBooking] = useState(route.params?.booking || null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [scrollToSectionIndex, setScrollToSectionIndex] = useState(0);
  const locale = getCurrentLocale();
  const enabledDates = {};
  bookings?.forEach(({ date }, index) => enabledDates[date] = { disabled: false, index });

  useEffect(() => {
    if (route.params?.booking && route.params?.booking !== booking) setBooking(route.params.booking);
  }, [route.params?.booking]);

  if (scrollToSectionIndex > 0 && bookings.length) {
    setTimeout(() => {
      setScrollToSectionIndex(0);
      return sectionListRef?.current?.scrollToLocation?.({
        sectionIndex: Math.max(Math.min(scrollToSectionIndex, bookings.length - 1), 0),
        itemIndex: 1,
      });
    }, 750);
  }

  const onRefresh = async () => {
    setLoading(true);
    const { sectionIndex, bookings } = await getBookings();
    if (route.params?.booking && scrollToSectionIndex === 0) {
      const openBookingIndex = bookings.findIndex(b => b.data.find(r => r.id === route.params.booking.id));
      setScrollToSectionIndex(openBookingIndex || sectionIndex);
    } else {
      setScrollToSectionIndex(sectionIndex);
    }
    setLoading(false);
  };

  const updateBooking = (booking) => {
    setBooking(booking);
    if (booking) {
      onBookingUpdated(booking);
    }
  };

  const jumpToDate = (date) => {
    sectionListRef?.current?.scrollToLocation?.({
      sectionIndex: enabledDates?.[date]?.index ?? 0,
      itemIndex: 1,
    });
  };

  useFocusEffect(useCallback(() => { onRefresh(); }, [route.params]));

  return (
    <View style={[Theme.styles.height, styles.container, Theme.XL ? [Theme.styles.webContainer, Theme.styles.webContainerWrapper] : null]}>
      <SectionList
        renderSectionHeader={({ section: { date } }) => (
          <TouchableOpacity onPress={() => setShowDatePicker(true)}>
            <HeaderComponent title={moment(date).format('dddd, ll')} style={styles.listHeader} />
          </TouchableOpacity>
        )}
        ref={sectionListRef}
        sections={bookings}
        numColumns={Theme.numberOfColumns}
        style={Theme.XL ? Theme.styles.padding16 : null}
        columnWrapperStyle={Theme.numberOfColums > 1 ? Theme.styles.alignStartSpaceBetween : null}
        keyExtractor={(item, index) => index}
        onRefresh={onRefresh}
        refreshing={loading}
        contentContainerStyle={!bookings?.length ? Theme.styles.height : null}
        onScrollToIndexFailed={() => {}}
        ListEmptyComponent={() => (
          <View style={[Theme.styles.flex, Theme.styles.column, Theme.styles.alignCenterCenter, Theme.styles.padding40]}>
            <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.fontSize20, Theme.styles.textCenter]}>{ translate('NOTHING_HERE_YET') }</Text>
            <MaterialCommunityIcons name='kitesurfing' color={Theme.styles.text.color} size={50} style={[Theme.styles.marginTop20, Theme.styles.marginBottom20]} />
            <Text style={[Theme.styles.text, Theme.styles.description, Theme.styles.textCenter]}>{ translate('GET_STARTED') }</Text>
          </View>
        )}
        renderItem={({ item, index }) => (
          <AnimateService
            properties={['opacity', 'left', 'top']}
            delay={index * 50}
            duration={500}
            Component={() => {
              const [width, setWidth] = useState(Theme.width - 32);
              const bookingContext = getBookingContext(item, context);
              const total = Object.values(item.bookings || { item }).reduce((agg, current) => {
                agg += current.attendees?.total || 0;
                return agg;
              }, 0);
              const carouselInterval = Math.floor((Math.random() * 5) + 1);
              const onLayout = (event) => setWidth(event.nativeEvent.layout.width);
              return (
                <TouchableOpacity onPress={() => setBooking({ ...item, bookingContext, totalAttendees: total })} style={item.public && !item.bookings?.[context.user?.id] ? styles.publicBookingContainer : {}} onLayout={onLayout}>
                  { item.public && !item.bookings?.[context.user?.id] ? (
                    <Carousel
                      data={bookingContext.service.media}
                      renderItem={(renderProps) => <CarouselItem {...renderProps} style={styles.carouselItem} />}
                      sliderWidth={width}
                      itemWidth={width}
                      autoplay={true}
                      autoplayDelay={carouselInterval * 1000}
                      autoplayInterval={5000}
                      loop={true}
                    />
                  ) : null}
                  <ListItemComponent
                    title={bookingContext?.service?.title?.[locale]}
                    subtitle={(bookingContext.plan?.title?.[locale] || '') + (item.duration ? '\n' + getDurationString(item.duration, bookingContext.plan?.dateRange ? (bookingContext.plan?.dateRangeUnit === 'night' ? 'NIGHT(S)' : 'DAY(S)') : '') : '')}
                    image={ICONS[bookingContext.service?.icon]}
                    style={item.public
                      ? index === 0
                        ? styles.publicBookingFirst
                        : styles.publicBooking
                      : {}
                    }
                    ActionComponent={() => (
                      <View style={[Theme.styles.column, Theme.styles.alignEndCenter]}>
                        <StatusLabelComponent status={context.user.admin || !item.public || item.bookings?.[context.user?.id] ? item.status : 'JOIN_NOW'} />
                        <View style={[Theme.styles.row, Theme.styles.alignCenterCenter, item.startTime ? Theme.styles.paddingTop5 : Theme.styles.paddingTop10]}>
                          <MaterialCommunityIcons name='human-greeting' color={Theme.styles.text.color} size={16} />
                          <Text style={[Theme.styles.text, Theme.styles.letterSpacing0, Theme.styles.fontSize12]}>{total || 0}{item.maxAttendees ? ' / ' + item.maxAttendees : ''}</Text>
                          { item.startTime ? (
                            <View style={[Theme.styles.row, Theme.styles.marginLeft5]}>
                              <MaterialCommunityIcons name='timer-outline' color={Theme.styles.text.color} size={16} />
                              <Text style={[Theme.styles.text, Theme.styles.letterSpacing0, Theme.styles.fontSize12]}> {moment.utc(item.startTime).format('hh:mm a')}</Text>
                            </View>
                          ) : null }
                        </View>
                      </View>
                    )}
                  />
                </TouchableOpacity>
              );
            }}
          />
        )}
      />
      <DatePickerComponent
        type='only-modal'
        show={showDatePicker}
        setShow={setShowDatePicker}
        onChange={jumpToDate}
        disabledByDefault={true}
        markedDates={enabledDates}
      />
      <BookingDetailComponent navigation={navigation} route={route} booking={booking} params={{navigateTo: route.name, navigateToParams: route.params}} setBooking={updateBooking} onDone={getBookings}/>
    </View>
  );
};
