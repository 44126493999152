import moment from 'moment';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ActivityIndicator,
  BackHandler,
  Linking,
  Platform,
  StyleSheet,
  Keyboard,
  Text,
  TouchableOpacity,
  View
} from 'react-native';
import {
  BottomSheetBackdrop,
  BottomSheetFooter,
  BottomSheetTextInput,
  BottomSheetModal,
} from '@gorhom/bottom-sheet';
import { BottomSheetScrollView } from '../common/libs/bottom-sheet';
import Toast from 'react-native-toast-message';
import StarRating from 'react-native-star-rating-widget';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import useKeyboard from '@rnhooks/keyboard';
import uuid from 'react-native-uuid';

import * as API from '../common/api.service';
import { Theme } from '../../theme';
import { Context } from '../Context';
import { translate, getCurrentLocale } from '../../lang';
import { getDurationString, getDateString, getPriceString, getTimeString, getNextRoute } from '../common/utils.service';
import { ListItemComponent } from '../common/list-item.component';
import { StatusLabelComponent } from '../common/status-label.component';
import { ButtonComponent } from '../common/button.component';
import { HeaderComponent } from '../common/header.component';
import { ReviewComponent } from './reviews';
import { ReviewResponseComponent } from './reviews/response';
import { OrderSummaryComponent } from './order-summary';
import { isResourceAvailable, ResourceTypes } from '../config/resources/detail';
import { ResourcesListComponent } from '../config/resources/list';
import { SelectorComponent } from '../common/selector.component';
import { DatePickerComponent } from '../common/datepicker.component';
import { TimePicker } from '../common/libs/time-picker/time-picker';

export const ICONS = {
  CRUISE: require('../../theme/assets/Cruise.png'),
  DIVING: require('../../theme/assets/Diving.png'),
  FISHING: require('../../theme/assets/Fishing.png'),
  GENERIC_ACTIVITY: require('../../theme/assets/GenericActivity.png'),
  GENERIC_SERVICE: require('../../theme/assets/GenericService.png'),
  JET_SKI: require('../../theme/assets/JetSki.png'),
  KAYAKING: require('../../theme/assets/Kayaking.png'),
  SAILING: require('../../theme/assets/Sailing.png'),
  SNORKELING: require('../../theme/assets/Snorkeling.png'),
  STANDARD_ROOM: require('../../theme/assets/StandardRoom.png'),
  SUPERIOR_ROOM: require('../../theme/assets/SuperiorRoom.png'),
};

const ROUTES = {
  'activity': 'Activities',
  'service': 'Services',
  'spa': 'Spa'
};

const styles = StyleSheet.create({
  placeholder: {
    minHeight: 120,
    height: 120,
    paddingHorizontal: 8,
    marginLeft: -16,
    marginRight: -16,
    marginTop: 8
  },
  payNowButton: {
    borderRadius: 100
  },
  starStyle: {
    marginHorizontal: .5
  },
  orderContainer: {
    marginTop: -25
  },
  textInput: {
    marginTop: 8,
    marginBottom: 10,
    borderRadius: 2,
    fontSize: 16,
    lineHeight: 20,
    padding: 8,
    borderWidth: 1,
    borderColor: Theme.palette.accent,
    color: Theme.palette.textDark
  },
  borderBottom: {
    borderBottomWidth: 1,
    borderBottomColor: Theme.palette.backgroundLight
  },
  discountBanner: {
    backgroundColor: Theme.palette.accent,
    borderRadius: 6
  },
  discountBannerText: {
    fontWeight: 'bold',
    color: Theme.palette.accentContrast
  },
  internalNotesLabelXL: {
    paddingBottom: 15,
    paddingTop: 10
  },
  paymentLinkLabelXL: {
    marginBottom: -24,
    paddingTop: 20
  }
});

export const BookingDetailComponent = ({ navigation, route, booking, setBooking, params, onDone }) => {
  const today = new Date().toISOString().split('T')[0];
  const bottomSheetModalRef = useRef(null);
  const assignResourcesBottomSheetModalRef = useRef(null);
  const scheduleBottomSheetModalRef = useRef(null);
  const context = useContext(Context);
  const [ visible ] = useKeyboard();
  const [loading, setLoading] = useState(false);
  const [resources, setResources] = useState(booking?.resources || []);
  const [schedules, setSchedules] = useState(booking?.schedules || []);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const locale = getCurrentLocale();
  const snapPoints = useMemo(() => ['80%'], []);

  useEffect(() => {
    if (booking) {
      bottomSheetModalRef.current?.present();
    }
    if (!selectedSchedule) {
      setResources(booking?.resources || []);
      setSchedules(booking?.schedules || []);
    }

    const backHandler = BackHandler.addEventListener('hardwareBackPress', () => {
      if (booking) {
        bottomSheetModalRef.current.close();
        setBooking(null);
        return true;
      }
      return false;
    });

    return () => backHandler.remove();
  }, [booking]);

  const edit = () => {
    const { bookingContext, ...item } = booking;
    bottomSheetModalRef.current?.dismiss();
    return navigation.navigate((params.navigateTo || '') + ROUTES[bookingContext.type], { screen: `${bookingContext.type}ServiceDetail`, params: { ...bookingContext, ...item, ...params } });
  };

  const join = (discount = 0, guest = context.user) => {
    const { bookingContext, ...item } = booking;
    bottomSheetModalRef.current?.dismiss();
    const nextRoute = getNextRoute('ServiceCalendar', bookingContext.service);
    navigation.navigate(`MyBookings${ROUTES[bookingContext.type]}`, {
      screen: `${bookingContext.type}${nextRoute}`,
      params: {
        navigateTo: 'MyBookings',
        parentBooking: booking,
        ...item,
        ...bookingContext,
        ...params,
        guest,
        ...(item.bookings?.[guest?.id] || {}),
        discount,
        date: {
          start: item.startDate,
          end: item.endDate
        }
      }
    });
    return true;
  };

  const addReservation = () => {
    bottomSheetModalRef.current?.dismiss();
    return navigation.navigate('GuestList', {
      select: (guest) => join(booking?.discount, guest),
    });
  };

  const save = async (updated = booking) => {
    const { bookingContext, ...toUpdate } = updated;
    setLoading(true);
    Keyboard.dismiss();
    await API.crud({
      operation: '_update',
      table: 'bookings',
      query: toUpdate
    });
    setLoading(false);
  };

  const withdraw = async () => {
    const bookings = { ...booking.bookings };
    delete bookings[context.user.id];
    booking.bookings = bookings;
    const { bookingContext, id, ...toUpdate } = booking;
    if (bookingContext.group.externalCrud?._delete?.active) {
      await API.crud({
        operation: '_custom',
        custom: bookingContext.group.externalCrud._delete,
        query: [booking, bookingContext.guest]
      });
    }
    let operation = '_update';
    let query = {
      id,
      ...toUpdate
    };
    await API.crud({
      operation,
      table: 'bookings',
      query
    });

    setBooking(null);
    Toast.hide();
    if (onDone) await onDone({ action: 'withdraw', booking: booking });
    setTimeout(() => Toast.show({
      type: 'success',
      autoHide: true,
      text1: translate('SUCCESS'),
      text2: translate('BOOKING_WITHDRAWN')
    }), 500);
  };

  const updateStatus = async (newStatus) => {
    booking.status = newStatus;
    const { bookingContext, ...query } = booking;
    if (bookingContext.group.externalCrud?._confirm?.active) {
      await API.crud({
        operation: '_custom',
        custom: bookingContext.group.externalCrud._confirm,
        query: [booking, bookingContext.guest]
      });
    }
    await API.crud({
      operation: '_update',
      table: 'bookings',
      query: query
    });
    Toast.hide();
    if (onDone) onDone({ action: 'updateStatus', status: newStatus, booking: booking });
    setTimeout(() => Toast.show({
      type: 'success',
      autoHide: true,
      text1: translate('SUCCESS'),
      text2: translate(`BOOKING_${newStatus}`)
    }), 500);
  };

  const remove = async () => {
    const { bookingContext, id } = booking;
    if (bookingContext.group.externalCrud?._delete?.active) {
      await API.crud({
        operation: '_custom',
        custom: bookingContext.group.externalCrud._delete,
        query: [booking, bookingContext.guest]
      });
    }
    let operation = '_delete';
    let query = {
      Key: {
        id: id
      }
    };
    await API.crud({
      operation,
      table: 'bookings',
      query
    });
    setBooking(null);
    Toast.hide();
    if (onDone) onDone({ action: 'delete', booking: booking });
    setTimeout(() => Toast.show({
      type: 'success',
      autoHide: true,
      text1: translate('SUCCESS'),
      text2: translate('BOOKING_REMOVED')
    }), 500);
  };

  const openPaymentLink = async () => {
    setLoading(true);
    if (Platform.OS === 'ios') {
      const supported = await Linking.canOpenURL(booking.paymentLink);
      if (supported) Linking.openURL(booking.paymentLink);
    } else if (Platform.OS === 'android') {
      Linking.openURL(booking.paymentLink);
    } else {
      // TODO navigate in web
    }
    setLoading(false);
  };

  const confirmAction = (callback, autosave, scheduling) => {
    return Toast.show({
      type: 'action',
      autoHide: false,
      text1: translate('CONFIRM_ACTION'),
      text2: translate('CONFIRM_ACTION_DESCRIPTION'),
      props: {
        actions: [{
          text: translate('NO'),
          onPress: Toast.hide
        }, {
          text: translate('YES'),
          class: 'error',
          onPress: async () => {
            setLoading(true);
            await callback();
            if (autosave && booking) {
              if (scheduling) {
                booking.schedules = booking.schedules || [];
                scheduling.resources = resources;
                const idx = booking.schedules.find(s => s.id === scheduling.id);
                if (idx) {
                  booking.schedules[idx] = scheduling;
                } else {
                  scheduling.id = uuid.v4();
                  booking.schedules.push(scheduling);
                }
                setSchedules([...booking.schedules]);
              } else {
                booking.resources = resources;
              }
              setBooking({ ...booking });
              await save(booking);
            }
            Toast.hide();
            setLoading(false);
          }
        }]
      }
    });
  };

  const toggleResource = (id) => {
    const idx = resources.indexOf(id);
    if (idx === -1) {
      resources.push(id);
    } else {
      resources.splice(idx, 1);
    }
    setResources([...resources]);
  };

  const openSchedule = (schedule) => {
    schedule = schedule || {
      startDate: booking.startDate,
      startTime: moment(booking.startTime || booking.startDate),
      endTime: moment(booking.startDate).add(booking?.bookingContenxt?.plan?.duration || 0, 'minutes'),
      resources: [],
      notes: ''
    };
    setSelectedSchedule({ ...schedule });
    setResources(schedule.resources || []);
    scheduleBottomSheetModalRef.current?.present();
  };

  // renders
  const renderBackdrop = useCallback(props => (
    <BottomSheetBackdrop
      {...props}
      disappearsOnIndex={-1}
      appearsOnIndex={0}
    />
  ), []);

  const renderFooter = useCallback(props => {
    const time = getTimeString(booking.startTime);
    const past = new Date(booking.startDate + 'T' + time) < new Date();
    return (
      <BottomSheetFooter {...props}>
        <View style={Theme.styles.row}>
          { past
            ? context.user.admin
              ? booking.review ? (<ReviewResponseComponent booking={booking} onChange={updated => setBooking(updated)} />) : null
              : <ReviewComponent booking={booking} onChange={updated => setBooking(updated)} />
            : !context.user.admin && booking?.public
              ? booking.bookings?.[context.user?.id]
                ? (
                  <>
                    <View style={Theme.styles.flex}>
                      <ButtonComponent safeArea={!visible} onPress={() => confirmAction(withdraw)} text={translate('WITHDRAW')} type='error' disabled={booking.startDate < today} />
                    </View>
                    <View style={Theme.styles.flex}>
                      <ButtonComponent safeArea={!visible} text={translate('EDIT')} onPress={() => join(booking?.discount)} disabled={booking.bookingContext.group.id === 'room' || booking.startDate < today || [booking.bookingContext.group.active, booking.bookingContext.service.active].includes(false)} />
                    </View>
                  </>
                ) : (
                  <View style={Theme.styles.flex}>
                    { booking?.discount ? (
                      <View style={[Theme.styles.padding16, Theme.styles.margin16, Theme.styles.row, Theme.styles.alignCenterStart, styles.discountBanner]}>
                        <MaterialCommunityIcons name='sale' color={Theme.palette.accentContrast} size={20} />
                        <Text style={[Theme.styles.text, Theme.styles.letterSpacing1, styles.discountBannerText, Theme.styles.paddingLeft10]}>
                          { translate('DISCOUNT_OFF_BANNER', { discount: booking?.discount }) }
                        </Text>
                      </View>
                    ) : null }
                    <ButtonComponent safeArea={!visible} onPress={() => join(booking?.discount)} text={translate('JOIN')} type='success' disabled={booking.attendees?.max && booking.attendees.max <= booking.attendees.total || booking.startDate < today} />
                  </View>
                )
              : (
                <>
                  <View style={Theme.styles.flex}>
                    <ButtonComponent safeArea={!visible} onPress={() => confirmAction(remove)} text={translate('REMOVE')} type='error' disabled={booking.startDate < today} />
                  </View>
                  <View style={Theme.styles.flex}>
                    <ButtonComponent safeArea={!visible} text={translate('EDIT')} onPress={edit} disabled={booking.bookingContext.group.id === 'room' || booking.startDate < today || [booking.bookingContext.group.active, booking.bookingContext.service.active].includes(false)} />
                  </View>
                </>
              )
          }
        </View>
      </BottomSheetFooter>
    );
  }, [booking, visible]);

  const renderAdditionalInfoField = ([key, value], nested) => {
    const field = booking.bookingContext.plan.additionalInformation.find(field => field.key === key);
    const options = field.options;
    return (
      <View key={key} style={[nested === true ? Theme.styles.paddingTop8 : Theme.styles.padding16, Theme.styles.paddingTop0]}>
        <Text style={[Theme.styles.text, Theme.styles.letterSpacing1]}>
          { field.label[locale] }
        </Text>
        { options ? options.filter(option => value.includes(option.value)).map(option => (
          <View key={option.value} style={[Theme.styles.row, Theme.styles.alignStartStart]}>
            <MaterialCommunityIcons name='check' color={Theme.palette.textDark} size={20} />
            <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.textBold, Theme.styles.marginLeft5]}>
              { option.label[locale] }
            </Text>
          </View>
        )) : (
          <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.textBold]}>
            { value } { field.hint?.[locale] ? `(${field.hint[locale]})` : '' }
          </Text>
        )}
      </View>
    );
  };

  const renderResource = (resourceId, idx) => {
    const resource = context.hotelConfig?.data?.resources?.find(r => r.id === resourceId) || {
      name: {
        [locale]: translate('UNKNOWN')
      },
      type: 'other',
    };
    return (
      <View key={idx} style={[Theme.styles.paddingVertical8, Theme.styles.row]}>
        <MaterialCommunityIcons name={ResourceTypes[resource.type].icon} color={Theme.palette.textDark} size={20} style={Theme.styles.marginRight10} />
        <View style={[Theme.styles.column, Theme.styles.alignStartCenter, Theme.styles.flex]}>
          <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.textBold]}>
            { ResourceTypes[resource.type].label }
          </Text>
          <Text style={[Theme.styles.text, Theme.styles.letterSpacing1]}>
            { resource.name[locale] }
          </Text>
        </View>
        <TouchableOpacity onPress={() => confirmAction(() => resources.splice(idx, 1), true, selectedSchedule)}>
          <MaterialCommunityIcons name='delete' color={Theme.palette.error} size={20} style={Theme.styles.marginLeft10} />
        </TouchableOpacity>
      </View>
    );
  };

  const renderAssignResourcesBottomSheetModalFooter = useCallback(props => {
    return (
      <BottomSheetFooter {...props}>
        <View style={Theme.styles.row}>
          <View style={Theme.styles.flex}>
            <ButtonComponent safeArea={!visible} text={translate('SAVE')} onPress={() => confirmAction(() => {
              assignResourcesBottomSheetModalRef.current?.dismiss();
            }, true, selectedSchedule)} />
          </View>
        </View>
      </BottomSheetFooter>
    );
  }, [visible, booking, schedules, resources, selectedSchedule]);

  const renderScheduleBottomSheetModalFooter = useCallback(props => {
    return (
      <BottomSheetFooter {...props}>
        <View style={Theme.styles.row}>
          <View style={Theme.styles.flex}>
            <ButtonComponent safeArea={!visible} text={translate('SAVE')} onPress={() => confirmAction(() => {
              scheduleBottomSheetModalRef.current?.dismiss();
            }, true, selectedSchedule)} />
          </View>
        </View>
      </BottomSheetFooter>
    );
  }, [visible, booking, schedules, resources, selectedSchedule]);

  const renderGuestDetails = (accountId) => {
    const guest = context.guests?.find(g => g.id === accountId);
    return (
      <>
        <View style={[Theme.styles.padding16, Theme.styles.paddingTop0]}>
          <Text style={[Theme.styles.text, Theme.styles.letterSpacing1]}>
            { translate('GUEST') }
          </Text>
          <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.textBold]}>
            { guest?.fullName || translate('NOT_FOUND') }
          </Text>
        </View>
        { guest ? (
          <View style={[Theme.styles.padding16, Theme.styles.paddingTop0]}>
            <Text style={[Theme.styles.text, Theme.styles.letterSpacing1]}>
              { translate('GUEST_EMAIL') }
            </Text>
            <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.textBold]}>
              { guest.email }
            </Text>
          </View>
        ) : null }
      </>
    );
  };

  let { attendees, status, price, additionalInformation } = booking || {};

  if (!context.user.admin && booking?.public) {
    attendees = booking.bookings?.[context.user?.id]?.attendees;
    price = booking.bookings?.[context.user?.id]?.price;
    additionalInformation = booking.bookings?.[context.user?.id]?.additionalInformation;

    status = attendees?.total
      ? booking.status
      : booking.totalAttendees >= booking.maxAttendees
        ? 'BOOKING_COMPLETED'
        : 'NOT_JOINED';
  }

  const bookings = Object.keys(booking?.bookings || {});

  const time = getTimeString(booking?.startTime);
  const past = booking && new Date(booking?.startDate + 'T' + time) < new Date();

  return booking ? (
    <View>
      <BottomSheetModal
        ref={bottomSheetModalRef}
        style={Theme.XL ? [Theme.styles.webContainer, Theme.styles.padding0] : null}
        index={booking ? 0 : -1}
        snapPoints={snapPoints}
        enablePanDownToClose={true}
        backdropComponent={renderBackdrop}
        footerComponent={booking ? renderFooter : null}
      >
        <BottomSheetScrollView>
          <View style={Theme.styles.paddingBottom20}>
            <ListItemComponent
              title={booking.bookingContext.group.title[locale]}
              subtitle={booking.bookingContext.service.title[locale]}
              image={ICONS[booking.bookingContext.service.icon]}
              ActionComponent={() => (
                <View style={[Theme.styles.column, Theme.styles.alignEndCenter]}>
                  <StatusLabelComponent
                    status={status}
                    editable={!past && context.user.admin ? ['PENDING', 'CONFIRMED', 'CANCELLED'].filter(s => Platform.OS === 'web' || s !== status) : null}
                    onChange={updateStatus}
                  />
                </View>
              )}
            />
            <View style={Theme.styles.padding8} />
            { context.user.admin && !booking.public ? renderGuestDetails(booking.accountId) : null }
            { booking.bookingContext.plan ? (
              <View style={[Theme.styles.padding16, Theme.styles.paddingTop0]}>
                <Text style={[Theme.styles.text, Theme.styles.letterSpacing1]}>
                  { translate('PLAN') }
                </Text>
                <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.textBold]}>
                  { booking.bookingContext.plan?.title?.[locale] }
                </Text>
              </View>
            ) : null
            }
            { booking.roomNumber ? (
              <View style={[Theme.styles.padding16, Theme.styles.paddingTop0]}>
                <Text style={[Theme.styles.text, Theme.styles.letterSpacing1]}>
                  { translate('ROOM_NUMBER') }
                </Text>
                <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.textBold]}>
                  { booking.roomNumber }
                </Text>
              </View>
            ) : null
            }
            <View style={[Theme.styles.padding16, Theme.styles.paddingTop0]}>
              <Text style={[Theme.styles.text, Theme.styles.letterSpacing1]}>
                { booking.endDate !== booking.startDate ? translate('DATES') : translate('DATE_AND_TIME') }
              </Text>
              <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.textBold]}>
                { getDateString({ start: booking.startDate, end: booking.endDate }, booking, booking) }
              </Text>
            </View>
            { booking.duration ? (
              <View style={[Theme.styles.padding16, Theme.styles.paddingTop0]}>
                <Text style={[Theme.styles.text, Theme.styles.letterSpacing1]}>
                  { translate('DURATION') }
                </Text>
                <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.textBold]}>
                  { getDurationString(booking.duration, booking.bookingContext.plan.dateRangeUnit === 'night' && 'NIGHT(S)') }
                </Text>
              </View>
            ) : null }
            { attendees?.total ? (
              <View style={[Theme.styles.padding16, Theme.styles.paddingTop0]}>
                <Text style={[Theme.styles.text, Theme.styles.letterSpacing1]}>
                  { translate('ATTENDEES') }
                </Text>
                { attendees.adults ? (
                  <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.textBold]}>
                    { translate('ADULTS') }: { attendees.adults }
                  </Text>
                ) : null
                }
                { attendees.kids ? (
                  <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.textBold]}>
                    { translate('KIDS') }: { attendees.kids }
                  </Text>
                ) : null
                }
                { attendees.infants ? (
                  <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.textBold]}>
                    { translate('INFANTS') }: { attendees.infants }
                  </Text>
                ) : null
                }
              </View>
            ) : null}
            { additionalInformation ? Object.entries(additionalInformation).map(renderAdditionalInfoField) : null}
            { booking.order ? (
              <View>
                <View style={[Theme.styles.padding16, Theme.styles.paddingTop0, Theme.styles.paddingBootom0]}>
                  <Text style={[Theme.styles.text, Theme.styles.letterSpacing1]}>
                    { translate('ORDER_SUMMARY') }
                  </Text>
                </View>
                <View style={[Theme.styles.padding8, styles.orderContainer]}>
                  <OrderSummaryComponent order={booking.order} />
                </View>
              </View>
            ) : null
            }
            { !booking.order && price ? (
              <View style={[Theme.styles.padding16, Theme.styles.paddingTop0]}>
                <Text style={[Theme.styles.text, Theme.styles.letterSpacing1]}>
                  { translate('PRICE') }
                </Text>
                <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.textBold]}>
                  { getPriceString(price) }
                </Text>
              </View>
            ) : null }
            { schedules?.length || context.user.admin ? (
              <View style={[Theme.styles.padding16, Theme.styles.paddingTop0]}>
                <View style={Theme.styles.row}>
                  <Text style={[Theme.styles.text, Theme.styles.letterSpacing1, Theme.styles.flex]}>
                    { translate('SCHEDULES') }
                  </Text>
                  { context.user.admin ? (
                    <ButtonComponent onPress={() => openSchedule()} text={translate('ADD')} inline outlined />
                  ) : null }
                </View>
                { schedules.map((schedule, idx) => (
                  <TouchableOpacity key={idx} onPress={() => openSchedule(schedule)} style={[Theme.styles.marginBottom4, Theme.styles.paddingVertical4, idx < schedules.length - 1 ? styles.borderBottom: null]}>
                    <View style={Theme.styles.row}>
                      <MaterialCommunityIcons name='calendar' color={Theme.palette.textDark} size={20} style={Theme.styles.marginRight10} />
                      <View style={[Theme.styles.column, Theme.styles.alignStartCenter, Theme.styles.flex]}>
                        <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.textBold]}>
                          { moment.utc(schedule.startDate).format('ll') } @ { moment.utc(schedule.startTime).format('hh:mm A') }
                        </Text>
                        <Text style={[Theme.styles.text, Theme.styles.description]}>
                          { translate('BACK_AT_TIME', { time: moment.utc(schedule.endTime).format('hh:mm A') }) }
                        </Text>
                      </View>
                      { context.user.admin ? (
                        <TouchableOpacity onPress={() => confirmAction(() => schedules.splice(idx, 1), true)}>
                          <MaterialCommunityIcons name='delete' color={Theme.palette.error} size={20} style={Theme.styles.marginLeft10} />
                        </TouchableOpacity>
                      ) : null}
                    </View>
                    { schedule.notes ? (
                      <View style={[Theme.styles.padding8, Theme.styles.paddingLeft30]}>
                        <Text style={[Theme.styles.text, Theme.styles.letterSpacing1, Theme.styles.textDark]}>
                          { translate('NOTES') }
                        </Text>
                        <Text style={[Theme.styles.text, Theme.styles.description]}>
                          { schedule.notes }
                        </Text>
                      </View>
                    ) : null }
                    { context.user.admin && booking?.resources ? (
                      <View style={[Theme.styles.padding8, schedule.notes ? Theme.styles.paddingTop0 : null, Theme.styles.paddingLeft30]}>
                        <Text style={[Theme.styles.text, Theme.styles.letterSpacing1, Theme.styles.textDark]}>
                          { translate('RESOURCES') }: { schedule.resources?.length || translate('ALL_FROM_BOOKING') }
                        </Text>
                      </View>
                    ) : null}
                  </TouchableOpacity>
                )) }
              </View>
            ) : null }
            { context.user.admin ? (
              <View style={[Theme.styles.paddingHorizontal16, Theme.styles.paddingBottom50]}>
                { booking.bookingContext.plan?.resources?.length ? (
                  <View style={Theme.styles.marginBottom8}>
                    <View style={Theme.styles.row}>
                      <Text style={[Theme.styles.text, Theme.styles.letterSpacing1, Theme.styles.flex]}>
                        { translate('RESOURCES') }
                      </Text>
                      <ButtonComponent onPress={() => assignResourcesBottomSheetModalRef?.current?.present()} text={translate('EDIT')} inline outlined />
                    </View>
                    { (selectedSchedule ? booking.resources : resources).map(renderResource) }
                  </View>
                ) : null }
                <Text style={[Theme.styles.text, Theme.styles.letterSpacing1, Theme.XL ? styles.internalNotesLabelXL : null]}>
                  { translate('INTERNAL_NOTES') }
                </Text>
                <View style={[Theme.styles.row, Theme.styles.flex]}>
                  <BottomSheetTextInput style={[Theme.styles.flex, styles.textInput]} onChangeText={internalNotes => setBooking({...booking, internalNotes})} value={booking.internalNotes} numberOfLines={5} multiline={true} />
                  <TouchableOpacity onPress={() => save()} style={Theme.styles.marginLeft10}>
                    { loading ? (
                      <ActivityIndicator />
                    ) : (
                      <MaterialCommunityIcons name='check' color={Theme.palette.textDark} size={20} style={styles.translateButton} />
                    )}
                  </TouchableOpacity>
                </View>
                { booking.public
                  ? (
                    <>
                      <View style={[Theme.styles.row, Theme.styles.alignCenterSpaceBetween, Theme.styles.paddingTop16]}>
                        <Text style={[Theme.styles.text, Theme.styles.letterSpacing1, Theme.styles.textBold, Theme.styles.textDark, Theme.styles.fontSize16]}>
                          { translate('RESERVATIONS') } ({bookings?.length || 0})
                        </Text>
                        <ButtonComponent onPress={addReservation} text={translate('ADD')} inline outlined />
                      </View>
                      { bookings?.length
                        ? bookings.map(key =>
                          <View key={key} style={[Theme.styles.borderBottom, Theme.styles.paddingBottom8]}>
                            <View style={[Theme.styles.paddingTop10, Theme.styles.marginLeft_16]}>
                              { renderGuestDetails(key) }
                            </View>
                            { booking.bookings[key].attendees?.total ? (
                              <View style={Theme.styles.paddingTop8}>
                                <Text style={[Theme.styles.text, Theme.styles.letterSpacing1]}>
                                  { translate('ATTENDEES') }
                                </Text>
                                { booking.bookings[key].attendees.adults ? (
                                  <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.textBold]}>
                                    { translate('ADULTS') }: { booking.bookings[key].attendees.adults }
                                  </Text>
                                ) : null
                                }
                                { booking.bookings[key].attendees.kids ? (
                                  <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.textBold]}>
                                    { translate('KIDS') }: { booking.bookings[key].attendees.kids }
                                  </Text>
                                ) : null
                                }
                                { booking.bookings[key].attendees.infants ? (
                                  <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.textBold]}>
                                    { translate('INFANTS') }: { booking.bookings[key].attendees.infants }
                                  </Text>
                                ) : null
                                }
                              </View>
                            ) : null}
                            { booking.bookings[key].additionalInformation ? Object.entries(booking.bookings[key].additionalInformation).map(info => renderAdditionalInfoField(info, true)) : null}
                            { !booking.order && booking.bookings[key].price ? (
                              <View style={Theme.styles.paddingTop8}>
                                <Text style={[Theme.styles.text, Theme.styles.letterSpacing1]}>
                                  { translate('PRICE') }
                                </Text>
                                <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.textBold]}>
                                  { getPriceString(booking.bookings[key].price) }
                                </Text>
                              </View>
                            ) : null }
                          </View>
                        ) : <TouchableOpacity onPress={addReservation} style={[styles.placeholder, Theme.styles.flex, Theme.styles.background, Theme.styles.column, Theme.styles.alignCenterCenter]}>
                          <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.textCenter]}>
                            { translate('NO_RESERVATIONS_YET') }
                          </Text>
                          <MaterialCommunityIcons name='plus-circle-outline' color={Theme.palette.text} size={25} style={Theme.styles.paddingVertical8} />
                          <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.textCenter, Theme.styles.description]}>
                            { translate('TOUCH_HERE_TO_ADD') }
                          </Text>
                        </TouchableOpacity>
                      }
                    </>
                  ) : (
                    <>
                      <Text style={[Theme.styles.text, Theme.styles.letterSpacing1, Theme.styles.paddingTop8, Theme.XL ? styles.paymentLinkLabelXL : null]}>
                        { translate('PAYMENT_LINK') }
                      </Text>
                      <View style={[Theme.styles.row, Theme.styles.flex]}>
                        <BottomSheetTextInput style={[Theme.styles.flex, styles.textInput]} onChangeText={paymentLink => setBooking({...booking, paymentLink})} value={booking.paymentLink} returnKeyType='done' onSubmitEditing={() => save()} keyboardType='url' />
                        <TouchableOpacity onPress={() => save()} style={Theme.styles.marginLeft10}>
                          { loading ? (
                            <ActivityIndicator />
                          ) : (
                            <MaterialCommunityIcons name='check' color={Theme.palette.textDark} size={20} style={styles.translateButton} />
                          )}
                        </TouchableOpacity>
                      </View>
                    </>
                  )
                }
              </View>
            ) : booking.paymentLink ? (
              <View style={Theme.styles.paddingHorizontal16}>
                <TouchableOpacity onPress={openPaymentLink} style={[Theme.styles.button, Theme.styles.row, Theme.styles.alignCenterCenter, styles.payNowButton]}>
                  <Text style={[Theme.styles.text, {color: Theme.palette.accentContrast}]}>{translate('PAY_NOW')}</Text>
                  <MaterialCommunityIcons name='open-in-new' color={Theme.palette.accentContrast} size={20} />
                </TouchableOpacity>
              </View>
            ) : null }
            { booking.review ? (
              <View style={[Theme.styles.padding16, Theme.styles.paddingTop0]}>
                <Text style={[Theme.styles.text, Theme.styles.letterSpacing1]}>
                  { translate('REVIEW') }
                </Text>
                <View style={[Theme.styles.row, Theme.styles.alignCenterSpaceBetween]}>
                  <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.textBold]}>
                    { booking.review.title }
                  </Text>
                  <StarRating
                    starSize={15}
                    starStyle={styles.starStyle}
                    color={Theme.palette.accent}
                    rating={booking.review.rating}
                    onChange={() => {}}
                    animationConfig={{
                      scale: 1
                    }}
                  />
                </View>
                <Text style={[Theme.styles.text, Theme.styles.description, Theme.styles.textDark]}>
                  { booking.review.comment }
                </Text>
              </View>
            ) : null }
          </View>
        </BottomSheetScrollView>
      </BottomSheetModal>

      {/* Resources Bottom Sheet */}
      <BottomSheetModal
        ref={assignResourcesBottomSheetModalRef}
        style={Theme.XL ? [Theme.styles.webContainer, Theme.styles.padding0] : null}
        index={0}
        snapPoints={snapPoints}
        enablePanDownToClose={true}
        backdropComponent={renderBackdrop}
        footerComponent={renderAssignResourcesBottomSheetModalFooter}
      >
        <BottomSheetScrollView>
          <View style={[Theme.styles.paddingHorizontal8, Theme.styles.paddingBottom80]}>
            <HeaderComponent
              title={translate('RESOURCES')}
            />
            <View style={Theme.styles.padding16}>
              <ResourcesListComponent navigation={navigation} route={route} nested={true} renderItem={({item}) => (
                <SelectorComponent
                  onPress={() => toggleResource(item._resource.id)}
                  selected={resources.includes(item._resource.id)}
                  title={item._resource.name[locale]}
                  subtitle={isResourceAvailable(item._resource) ? translate('AVAILABLE') : translate('NOT_AVAILABLE')}
                  value={resources.includes(item._resource.id) ? translate('ASSIGNED') : '' }
                />
              )} />
            </View>
          </View>
        </BottomSheetScrollView>
      </BottomSheetModal>

      {/* Schedule Bottom Sheet */}
      <BottomSheetModal
        ref={scheduleBottomSheetModalRef}
        style={Theme.XL ? [Theme.styles.webContainer, Theme.styles.padding0] : null}
        index={0}
        snapPoints={snapPoints}
        enablePanDownToClose={true}
        backdropComponent={renderBackdrop}
        footerComponent={renderScheduleBottomSheetModalFooter}
        onDismiss={() => {
          setSelectedSchedule(null);
          setResources(booking?.resources || []);
        }}
      >
        <BottomSheetScrollView>
          <View style={[Theme.styles.paddingHorizontal16, Theme.styles.paddingBottom80]}>
            <HeaderComponent title={translate('SCHEDULE')} />
            <Text style={[Theme.styles.text, Theme.styles.letterSpacing1, Theme.styles.paddingTop16, Theme.styles.paddingBottom8]}>
              { translate('DATE') }
            </Text>
            <DatePickerComponent
              value={selectedSchedule?.startDate}
              onChange={(startDate) => setSelectedSchedule({ ...selectedSchedule, startDate })}
            />
            <View style={[Theme.styles.paddingTop16, Theme.styles.row, Theme.styles.alignCenterCenter]} >
              <Text style={[Theme.styles.text, Theme.styles.letterSpacing1, Theme.styles.paddingRight16, Theme.styles.flex]}>
                {translate('START_HOUR')}
              </Text>
              <TimePicker value={selectedSchedule?.startTime} onChange={startTime => setSelectedSchedule({...selectedSchedule, startTime})} />
            </View>
            <View style={[Theme.styles.paddingTop16, Theme.styles.row, Theme.styles.alignCenterCenter]} >
              <Text style={[Theme.styles.text, Theme.styles.letterSpacing1, Theme.styles.paddingRight16, Theme.styles.flex]}>
                {translate('END_HOUR')}
              </Text>
              <TimePicker value={selectedSchedule?.endTime} onChange={endTime => setSelectedSchedule({...selectedSchedule, endTime})} />
            </View>
            <Text style={[Theme.styles.text, Theme.styles.letterSpacing1, Theme.styles.paddingTop8]}>
              { translate('NOTES') }
            </Text>
            <BottomSheetTextInput style={[Theme.styles.flex, styles.textInput]} onChangeText={notes => setSelectedSchedule({...selectedSchedule, notes})} value={selectedSchedule?.notes} numberOfLines={5} multiline={true} />
            { booking?.resources?.length ? (
              <View style={Theme.styles.marginBottom8}>
                <View style={Theme.styles.row}>
                  <Text style={[Theme.styles.text, Theme.styles.letterSpacing1, Theme.styles.flex]}>
                    { translate('OVERRIDE_RESOURCES') }
                  </Text>
                  <ButtonComponent onPress={() => assignResourcesBottomSheetModalRef?.current?.present()} text={translate('EDIT')} inline outlined />
                </View>
                { resources.map(renderResource) }
              </View>
            ) : null }
            { selectedSchedule?.id ? (
              <View style={Theme.styles.paddingTop16}>
                <ButtonComponent text={translate('DELETE_SCHEDULE')} type='error' onPress={() => confirmAction(() => schedules.splice(schedules.findIndex(s => s.id === selectedSchedule.id), 1), true)} outlined />
              </View>
            ) : null}
          </View>
        </BottomSheetScrollView>
      </BottomSheetModal>
    </View>
  ) : null;
};
