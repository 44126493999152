import React from 'react';
import { View, StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  selectorContainer: {
    position: 'absolute',
    opacity: 0
  }
});
const ContextMenu = function ({
  actions,
  onPress,
  status,
  children
}) {
  const onChange = (evt) => {
    const index = actions.findIndex(a => a.id === evt.target.value);
    onPress({
      nativeEvent: { index }
    });
  };

  return (
    <View>
      { children }
      <select style={styles.selectorContainer} onChange={onChange} value={status}>
        { actions.map(action => (
          <option key={action.id} value={action.id}>{ action.title }</option>
        ))}
      </select>
    </View>
  );
};
export default ContextMenu;
