import Toast from 'react-native-toast-message';

import * as API from '../common/api.service';
import { translate } from '../../lang';

export const upsertBooking = async (
  context,
  group,
  slot,
  booking,
  setLoading = () => {}
) => {
  if (!context.user || context.user.guest) { // || !context.user.status === 'CONFIRMED') {
    if (context.hotelConfig?.data?.guests.allowSignup) {
      API.setContext({
        ...context,
        showSignUp: true
      });
      return false;
    } else {
      Toast.show({
        type: 'error',
        visibilityTime: 20000,
        text1: translate('ACTIVE_USER_REQUIRED'),
        text2: translate('ACTIVE_USER_REQUIRED_DESCRIPTION')
      });
      return false;
    }
  }
  setLoading(true);
  const operation = booking.oldId ? '_update' : '_create';
  booking.accountId = booking.accountId || context.user.id;
  // Handle public bookings
  if (booking.public) {
    booking.bookings = booking.bookings || {};
    const { attendees, additionalInformation, price } = booking;
    booking.bookings[booking.accountId] = {
      attendees,
      additionalInformation,
      price
    };

    for (let key in booking.bookings[booking.accountId]) {
      delete booking[key];
    }
    booking.accountId = context.hotelConfig.hotelId + '#public';
  } else {
    delete booking.maxAttendees;
    booking.public = false;
  }
  // Autoassigning resources
  if (slot.resources?.length && !booking.resources?.length) {
    const assignedTypes = [];
    booking.resources = [];
    slot.resources.forEach(resource => {
      if (!assignedTypes.includes(resource.type)) {
        assignedTypes.push(resource.type);
        booking.resources.push(resource.id);
      }
    });
  }
  if (group.externalCrud?.[operation]?.active) {
    // TODO Move this to backend for further generalization
    // TODO Move this to backend for further generalization
    // TODO Move this to backend for further generalization
    const externalCrud = await API.crud({
      operation: '_custom',
      custom: group.externalCrud[operation],
      query: [booking, context.user]
    });
    if (externalCrud.uuid) {
      const id = booking.id.split('#');
      id.pop();
      id.push('%' + externalCrud.uuid + '%');
      booking.id = id.join('#');
    }
  }
  delete booking.integrationInfo;
  await API.crud({
    operation,
    table: 'bookings',
    query: booking
  });
  setLoading(false);
  context.user.bookings = context.user.bookings || [];
  context.user.bookings.push(booking);
  Toast.show({
    type: 'success',
    visibilityTime: 10000,
    text1: translate('BOOKING_REQUESTED'),
    text2: translate('BOOKING_REQUESTED_DESCRIPTION')
  });
  return true;
};

